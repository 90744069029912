import { DashboardModule, Form } from '@campaignhub/suit-theme'
import { BusinessUnitFormPayload } from '@hooks/useBusinessUnit'

interface InvoicePrefixProps {
  businessUnitFormPayload: BusinessUnitFormPayload,
}

const InvoicePrefix = (props: InvoicePrefixProps) => {
  const { businessUnitFormPayload } = props || {}
  const {
    entityState: {
      invoicePrefix,
    },
    errors,
    handlers
   } = businessUnitFormPayload

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column', padding: 'large' }}
      title="Invoice Prefix"
    >
      <Form>
        <Form.Row>
          <Form.Field label="* Invoice Prefix" errorMessage={errors.invoicePrefix}>
            <input
              name="invoicePrefix"
              type="text"
              value={invoicePrefix}
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default InvoicePrefix
